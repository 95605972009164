// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buttons-js": () => import("/opt/build/repo/src/pages/buttons.js" /* webpackChunkName: "component---src-pages-buttons-js" */),
  "component---src-pages-cards-js": () => import("/opt/build/repo/src/pages/cards.js" /* webpackChunkName: "component---src-pages-cards-js" */),
  "component---src-pages-colors-js": () => import("/opt/build/repo/src/pages/colors.js" /* webpackChunkName: "component---src-pages-colors-js" */),
  "component---src-pages-containers-js": () => import("/opt/build/repo/src/pages/containers.js" /* webpackChunkName: "component---src-pages-containers-js" */),
  "component---src-pages-content-js": () => import("/opt/build/repo/src/pages/content.js" /* webpackChunkName: "component---src-pages-content-js" */),
  "component---src-pages-design-js": () => import("/opt/build/repo/src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-footer-js": () => import("/opt/build/repo/src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-form-js": () => import("/opt/build/repo/src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-grid-2-column-js": () => import("/opt/build/repo/src/pages/grid-2-column.js" /* webpackChunkName: "component---src-pages-grid-2-column-js" */),
  "component---src-pages-grid-3-column-js": () => import("/opt/build/repo/src/pages/grid-3-column.js" /* webpackChunkName: "component---src-pages-grid-3-column-js" */),
  "component---src-pages-grid-4-column-js": () => import("/opt/build/repo/src/pages/grid-4-column.js" /* webpackChunkName: "component---src-pages-grid-4-column-js" */),
  "component---src-pages-headers-js": () => import("/opt/build/repo/src/pages/headers.js" /* webpackChunkName: "component---src-pages-headers-js" */),
  "component---src-pages-icons-js": () => import("/opt/build/repo/src/pages/icons.js" /* webpackChunkName: "component---src-pages-icons-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-navigation-js": () => import("/opt/build/repo/src/pages/navigation.js" /* webpackChunkName: "component---src-pages-navigation-js" */),
  "component---src-pages-typography-js": () => import("/opt/build/repo/src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */)
}

